import { Controller } from "stimulus";
import { rebind } from "../rebind";
import { railsAjax } from "../../services/rails-ajax";
export default class extends Controller {
  //
  // life cycle
  //
  initialize() {
    this.cardSameHeight();
    $(window).resize(() => {
      this.cardSameHeight();
    });
  }

  //
  // target
  //
  static targets = [
  ]

  //
  // article
  //
  cardSameHeight() {
    // 一度消す
    $('div[data-target="top--layout.card"]').each((i, card) => {
      $(card).height('');
    });
    if (window.innerWidth >= 768) {
      $('div[data-target="top--layout.cardRow"]').each((i, row) => {
        const cards = $(row).find('div[data-target="top--layout.card"]');
        let height = 0;
        cards.each((i, card) => {
          if (height < $(card).height()) {
            height = $(card).height();
          }
        });
        cards.each((i, card) => {
          $(card).height(height);
        });
      });
    }
  }
};

