export const rebind = (object) => {
  // knockout
  if (ko && window.viewModel) {
    ko.rebind = function(object) {
      $(object).children().each((index, element) => {
        ko.applyBindings(window.viewModel, element);
      });
    };
  };

  // popover
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').tooltip();

  // validator
  if (window.components.validator) {
    window.components.validator.rebind();
  };

  // if value
  if (window.components.switcherIfValue) {
    $('.jsIfValue').each(() => {
      window.components.switcherIfValue.setup($(this));
    });
  };

  // toggle switch
  if (window.components.toggleSwitch) {
    $('.form-switch').each((index, element) => {
      window.components.toggleSwitch.setup(element);
    });
  }

  $('.form-chip[data-toggle="chip"]').each((i, chip) => {
    window.components.formsChip.setup($(chip));
  });
};
