// { type:, url:, data:, done:, fail, }
export const railsAjax = (arg) => {
  let ajaxArg = { type: arg.type, url:  arg.url, data: arg.data, e: arg.e };
  let submitButtonToggle = false;

  if (Object.prototype.toString.call(arg.data) === "[object FormData]") {
    ajaxArg.processData = false;
    ajaxArg.contentType = false;
  }

  // a.btnが送信されているとき
  if (ajaxArg.e && ajaxArg.e.target) {
    var $btn = $(ajaxArg.e.target);
    if ($btn.prop("tagName") == 'A') {
      submitButtonToggle = true;
      window.components.btn.sending(ajaxArg.e);
    }
  }
  // formを送信していないときは、uthenticity_tokenがあれば付与
  if (Object.prototype.toString.call(arg.data) !== "[object FormData]") {
    const authToken = $("meta[name=csrf-token]").attr("content");
    if (authToken) {
      if (!ajaxArg.data) {
        ajaxArg.data = {};
      }
      ajaxArg.data.authenticity_token = authToken;
    }
  }
  $.ajax(ajaxArg)
  .done((response, textStatus, jqXHR) => {
    if (submitButtonToggle) {
      window.components.btn.reset(ajaxArg.e);
    }
    if (arg.done) {
      arg.done(response, textStatus, jqXHR);
    }
  })
  .fail((jqXHR, textStatus, errorThrown) => {
    if (submitButtonToggle) {
      window.components.btn.reset(ajaxArg.e);
    }
    if (jqXHR.status == 500) {
      if (window.components.alertSlide) {
        window.components.alertSlide.callByFunction("システムエラーが発生しました");
      } else {
        console.log("システムエラーが発生しました");
      }
    } else {
      if (arg.fail) {
        arg.fail(jqXHR.responseJSON);
      } else {
        if (window.components.alertSlide) {
          window.components.alertSlide.callByFunction("システムエラーが発生しました");
        } else {
          console.log("システムエラーが発生しました");
        }
      }
    }
  });
};
